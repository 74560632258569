import Layout from './components/Layout';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (<>
    <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>        
      <Layout />       
    </BrowserRouter>
  </>
  )
}

export default App;
