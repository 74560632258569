import React from 'react';
import malabarLogo from '../../../assets/images/logo.PNG'

const Carousel = () => {
  return (
    <div>
        <div className="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-img">
                            <img src={malabarLogo} alt="Malabar Logo" style={{ transform: "scale(0.75)"}}/>                            
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header">
                                <p>About Us</p>
                                <h2>Cooking Since two decades</h2>
                            </div>
                            <div className="about-text">
							 <p>
							 Over the past <span className="highlighter">20 years</span> we have catered from <span className="highlighter">10 guests to 3000 guests</span> with same passion with unique touch and creativity. 
							 </p>
							 <p>
							 Our goal is to create a memorable experience that lasts a life time. Exquisite flavor food is the perfect accompaniment to weddings. It’s something that everyone will appreciate and remember – <span className="highlighter">and it’s what we do best.</span>
							 </p>
                             {/*<a className="btn custom-btn" href="">View Menu</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Carousel