import React from 'react';

const Contact = () => {
  return (
    <div>
        <div className="contact">
            <div className="container">
                <div className="section-header text-center">
                    <p>Contact Us</p>
                    <h2>Contact For Any Query</h2>
                </div>
                <div className="row align-items-center contact-information">
                    <div className="col-md-6 col-lg-3">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fa fa-map-marker-alt"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Address</h3>
                                <p>911 Greenwood Rd # B, Glenview, IL 60025</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fa fa-phone-alt"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Call Us</h3>
                                <p>847 998 5635</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Email Us</h3>
                                <p>malabar4you@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <i className="fa fa-share"></i>
                            </div>
                            <div className="contact-text">
                                <h3>Follow Us</h3>
                                <div className="contact-social">                                    
                                    <a href="https://www.facebook.com/royalmalabarcatering/"><i className="fab fa-facebook-f"></i></a>                                    
                                    <a href="https://www.instagram.com/royalmalabar.catering/"><i className="fab fa-instagram"></i></a>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row contact-form">
                    <div className="col-md-12">
                        <iframe src="https://www.google.com/maps/embed/v1/place?q=911+Greenwood+Rd+b,+Glenview,+IL+60025,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameborder="0" style={{border:"0px"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact