import React from 'react';
import featureImage5 from '../../../assets/images/feature-5.jpg'
import featureImage6 from '../../../assets/images/feature-6.jpg'

const Carousel = () => {
  return (
    <div>
        <div className="menu">
            <div className="container">
                <div className="section-header text-center">
                    <p>Food Menu</p>
                    <h2>Everyday Menu</h2>
					<p>We have more specials on Saturday and Sunday including Pothichoru and Pothi Biryani</p>
					<div className="feature-text">
                            <div className="feature-img">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <img src={featureImage5} alt="Image"/>
										 <img src={featureImage6} alt="Image"/>
                                    </div>
                                                                      
                                </div>
                            </div>                            
                        </div>
                </div>
                <div className="menu-tab">
                    <ul className="nav nav-pills justify-content-center">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="pill" href="#burgers">Breakfast</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="pill" href="#snacks">Snacks</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="pill" href="#beverages">Rice</a>
                        </li>
						<li className="nav-item">
                            <a className="nav-link" data-toggle="pill" href="#veg">Veg</a>
                        </li>
						<li className="nav-item">
                            <a className="nav-link" data-toggle="pill" href="#nonVeg">Non Veg</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="burgers" className="container tab-pane active">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Kallappam (Pack of 5)</span></h3>
                                            <p>Soft, tasty pancake is made with fermented rice and coconut batter.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Palappam (Pack of 5)</span></h3>
                                            <p>Fermented rice batter and coconut milk, traditionally cooked in an appachatti.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Dosa + Chutney (Pack of 5)</span></h3>
                                            <p>Thin savory pancake, fermented batter of ground black lentils and rice</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Masala Dosa + Chutney</span></h3>
                                            <p>Thin savory pancake, fermented batter of ground black lentils and rice served with potato curry</p>
                                        </div>
                                    </div>
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Veg Kurma</span></h3>
                                            <p>Combination of vegetables like – carrots, cauliflower, potatoes, french beans, green peas.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Egg Curry</span></h3>
                                            <p>made with hard boiled eggs, onions, tomatoes, whole & ground spices and herbs</p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div id="snacks" className="container tab-pane fade">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Parippuvada</span></h3>
                                            <p> Parippuvada or Masala Vada is crunchy chana dal fritters, popular in South India.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Uzhunnu Vada</span></h3>
                                            <p>Snack of donut shaped lentil fritters that are fluffy, crispy, soft and delicious</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Vazhakkappam (Plantain Fritter)</span></h3>
                                            <p>Made from a ripened plantain called 'Nendram Pazham' which is a local fruit found in most of the region in Kerala.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Meat Puffs</span></h3>
                                            <p>Minced meat seasoned with garlic, pepper, nutmeg and salt, cooked in a sauce of tomato, oil and onions inside puff pastry</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Egg Puffs</span></h3>
                                            <p>Boiled egg seasoned with garlic, pepper, nutmeg and salt, cooked in a sauce of tomato, oil and onions inside puff pastry</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Sughiyan</span></h3>
                                            <p>Deep fried Kerala snack made with whole green gram, jaggery and coconut.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                       
                                        <div className="menu-text">
                                            <h3><span>Veg Samosa</span></h3>
                                            <p>Deep fried Samosa sheet filled with vegetables seasoned with kerala spices</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Chicken Samosa</span></h3>
                                            <p>Deep fried Samosa sheet filled with chicken seasoned with kerala spices</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Meat Samosa</span></h3>
                                            <p>Deep fried Samosa sheet filled with meat seasoned with kerala spices</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                       
                                        <div className="menu-text">
                                            <h3><span>Bonda</span></h3>
                                            <p>Made using whole wheat flour , banana and jaggery</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="beverages" className="container tab-pane fade">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Chicken Dum Biryani</span></h3>
                                            <p>Layering fragrant basmati rice over chicken marinated in a rich, delicious mix of spices and yogurt.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Mutton Dum Biryani</span></h3>
                                            <p>Layering fragrant basmati rice over mutton marinated in a rich, delicious mix of spices and yogurt.</p>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Veg Fried Rice</span></h3>
                                            <p>Long grained rice, mixture of warm peas, carrots and onions mixed all together!</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Chicken Fried Rice</span></h3>
                                            <p>Long grained rice, mixture of warm peas, carrots and onions with chicken mixed all together!</p>
                                        </div>
                                    </div>      
                                </div>
                            </div>
                        </div>
						<div id="veg" className="container tab-pane fade">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Aviyal</span></h3>
                                            <p>Mix vegetables in a coconut and yogurt sauce with a finishing of coconut oil and curry leaves.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Thoran (2 / 3 available everyday)</span></h3>
                                            <p>Stir-fried vegetable dish, simply seasoned with curry leaf, mustard seed, chili, and coconut.</p>
                                        </div>
                                    </div>    
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Veg Kurma</span></h3>
                                            <p>Combination of vegetables like – carrots, cauliflower, potatoes, french beans, green peas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Mezhukkupuratty (2 / 3 available everyday)</span></h3>
                                            <p>Vegetable is stir-fried with spices.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Sambar</span></h3>
                                            <p>Lentil-based vegetable stew, cooked with pigeon pea and tamarind broth.</p>
                                        </div>
                                    </div> 
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Pulissery</span></h3>
                                            <p>Spiced buttermilk curry with a coconut base. The coconut is ground with spices much like for thoran but the curry is sour</p>
                                        </div>
                                    </div> 									
                                </div>
                            </div>
                        </div>
						<div id="nonVeg" className="container tab-pane fade">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Chicken Curry</span></h3>
                                            <p>Chicken in an onion- and tomato-based sauce, flavoured with ginger, garlic, tomato puree, chilli peppers and a variety of spices</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Mutton Curry</span></h3>
                                            <p>Mutton in an onion- and tomato-based sauce, flavoured with ginger, garlic, tomato puree, chilli peppers and a variety of spices</p>
                                        </div>
                                    </div>    
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Malabar Fish Curry</span></h3>
                                            <p>Creamy fish curry with goodness of coconut milk and full of chilllies, coriander and mustard seeds.</p>
                                        </div>
                                    </div>
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Kingfish Curry</span></h3>
                                            <p>Kottayam style fish curry made with Kodampuli – Malabar Tamarind and Kingfish.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Chilli Chicken</span></h3>
                                            <p>Sweet, spicy & slightly sour crispy appetizer made with chicken, bell peppers, garlic, chilli sauce & soya sauce.</p>
                                        </div>
                                    </div>
                                    <div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Butter Chicken</span></h3>
                                            <p>Made with chicken, spices, tomatoes and cream.Its sauce is known for its rich texture.</p>
                                        </div>
                                    </div> 
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Beef Fry</span></h3>
                                            <p>Staple South Indian dish. It's spiced with a special blend of spices, including meat masala.</p>
                                        </div>
                                    </div> 	
									<div className="menu-item">                                        
                                        <div className="menu-text">
                                            <h3><span>Pork Fry</span></h3>
                                            <p>Staple South Indian dish. It's spiced with a special blend of spices, including meat masala.</p>
                                        </div>
                                    </div>									
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Carousel