import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Home';
import Header from '../Header';
import Footer from '../Footer';
import MenuPage from '../Menu';

const Layout = () => {
    return (
        <div>
            <Header />
            
            <Routes>
                <Route exact path='/' element={<HomePage />} />
                <Route exact path='/menu' element={<MenuPage />} />
            </Routes>

            <Footer />
        </div>
    )
}

export default Layout;