import React from 'react';


const MenuPage = () => {
  return (
    <div>
        <div class="page-header mb-0">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>Food Menu</h2>
                    </div>
                    <div class="col-12">
                        <a href="">Home</a>
                        <a href="">Menu</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="menu">
            <div className="menu-tab">
                <ul className="nav nav-pills justify-content-center">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="pill" href="#burgers">Breakfast & Snacks</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#snacks">Appetizers</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#beverages">Rice</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#veg">Veg</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#nonVeg">Non Veg</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div id="burgers" className="container tab-pane active">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Kallappam</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Palappam</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Dosa</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Idiyappam</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Idli</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Plain Naan</span><strong>$1.5</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Plain Naan</span><strong>$1.5</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Kozhukkatta</span><strong>$1 - $1.5</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Tandoori Chicken Wrap</span><strong>$5.99</strong></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Parippuvada</span><strong>$1</strong></h3>                                       
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Uzhunnu Vada</span><strong>$1</strong></h3>                                        
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Vazhakkappam (Plantain Fritter)</span><strong>$1</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Meat Puffs</span><strong>$1.25</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Egg Puffs</span><strong>$1.25</strong></h3>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Beef Cutlet</span><strong>$1.25</strong></h3>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Cutlet</span><strong>$1.25</strong></h3>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Vegetable Cutlet</span><strong>$1.25</strong></h3>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Kabab</span><strong>$1.5</strong></h3>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Beef Kabab</span><strong>$1.5</strong></h3>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Tandoori Chicken</span><strong>$1 - $1.5</strong></h3>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div id="snacks" className="container tab-pane fade">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Parippuvada</span></h3>
                                        <p> Parippuvada or Masala Vada is crunchy chana dal fritters, popular in South India.</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Uzhunnu Vada</span></h3>
                                        <p>Snack of donut shaped lentil fritters that are fluffy, crispy, soft and delicious</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Vazhakkappam (Plantain Fritter)</span></h3>
                                        <p>Made from a ripened plantain called 'Nendram Pazham' which is a local fruit found in most of the region in Kerala.</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Meat Puffs</span></h3>
                                        <p>Minced meat seasoned with garlic, pepper, nutmeg and salt, cooked in a sauce of tomato, oil and onions inside puff pastry</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Egg Puffs</span></h3>
                                        <p>Boiled egg seasoned with garlic, pepper, nutmeg and salt, cooked in a sauce of tomato, oil and onions inside puff pastry</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Sughiyan</span></h3>
                                        <p>Deep fried Kerala snack made with whole green gram, jaggery and coconut.</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                       
                                    <div className="menu-text">
                                        <h3><span>Veg Samosa</span></h3>
                                        <p>Deep fried Samosa sheet filled with vegetables seasoned with kerala spices</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Samosa</span></h3>
                                        <p>Deep fried Samosa sheet filled with chicken seasoned with kerala spices</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Meat Samosa</span></h3>
                                        <p>Deep fried Samosa sheet filled with meat seasoned with kerala spices</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                       
                                    <div className="menu-text">
                                        <h3><span>Bonda</span></h3>
                                        <p>Made using whole wheat flour , banana and jaggery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="beverages" className="container tab-pane fade">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Dum Biryani</span><strong>$10</strong></h3>
                                        <p>$100 Full Tray | $80 Medium Tray | $40 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Mutton Dum Biryani</span><strong>$13</strong></h3>
                                        <p>$130 Full Tray | $100 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Beef Dum Biryani</span><strong>$13</strong></h3>
                                        <p>$130 Full Tray | $100 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>  
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Vegetable Dum Biryani</span><strong>$13</strong></h3>
                                        <p>$130 Full Tray | $100 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>  
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Ambur Chicken Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $100 Medium Tray | $50 Half Tray</p>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Surbiyaan Chicken Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $100 Medium Tray | $50 Half Tray</p>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Mallika Goat Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $100 Medium Tray | $50 Half Tray</p>
                                    </div>
                                </div>                                    
                            </div>
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Talassery Chicken Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $90 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Malabari Chicken Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $90 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Ravuthar Beef Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $90 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Thalappakatti Goat Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $90 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Thalappakatti Chicken Biryani</span><strong>$13</strong></h3>
                                        <p>$120 Full Tray | $90 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Veg Fried Rice</span></h3>
                                        <p>$130 Full Tray | $100 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Fried Rice</span></h3>
                                        <p>$130 Full Tray | $100 Medium Tray | $55 Half Tray</p>
                                    </div>
                                </div>      
                            </div>
                        </div>
                    </div>
                    <div id="veg" className="container tab-pane fade">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Aviyal</span></h3>
                                        <p>Mix vegetables in a coconut and yogurt sauce with a finishing of coconut oil and curry leaves.</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Thoran (2 / 3 available everyday)</span></h3>
                                        <p>Stir-fried vegetable dish, simply seasoned with curry leaf, mustard seed, chili, and coconut.</p>
                                    </div>
                                </div>    
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Veg Kurma</span></h3>
                                        <p>Combination of vegetables like – carrots, cauliflower, potatoes, french beans, green peas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Mezhukkupuratty (2 / 3 available everyday)</span></h3>
                                        <p>Vegetable is stir-fried with spices.</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Sambar</span></h3>
                                        <p>Lentil-based vegetable stew, cooked with pigeon pea and tamarind broth.</p>
                                    </div>
                                </div> 
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Pulissery</span></h3>
                                        <p>Spiced buttermilk curry with a coconut base. The coconut is ground with spices much like for thoran but the curry is sour</p>
                                    </div>
                                </div> 									
                            </div>
                        </div>
                    </div>
                    <div id="nonVeg" className="container tab-pane fade">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Chicken Curry</span></h3>
                                        <p>Chicken in an onion- and tomato-based sauce, flavoured with ginger, garlic, tomato puree, chilli peppers and a variety of spices</p>
                                    </div>
                                </div>
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Mutton Curry</span></h3>
                                        <p>Mutton in an onion- and tomato-based sauce, flavoured with ginger, garlic, tomato puree, chilli peppers and a variety of spices</p>
                                    </div>
                                </div>    
                                <div className="menu-item">                                        
                                    <div className="menu-text">
                                        <h3><span>Malabar Fish Curry</span></h3>
                                        <p>Creamy fish curry with goodness of coconut milk and full of chilllies, coriander and mustard seeds.</p>
                                </div>
                            </div>
                            <div className="menu-item">                                        
                                <div className="menu-text">
                                    <h3><span>Kingfish Curry</span></h3>
                                    <p>Kottayam style fish curry made with Kodampuli – Malabar Tamarind and Kingfish.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="menu-item">                                        
                                <div className="menu-text">
                                    <h3><span>Chilli Chicken</span></h3>
                                    <p>Sweet, spicy & slightly sour crispy appetizer made with chicken, bell peppers, garlic, chilli sauce & soya sauce.</p>
                                </div>
                            </div>
                            <div className="menu-item">                                        
                                <div className="menu-text">
                                    <h3><span>Butter Chicken</span></h3>
                                    <p>Made with chicken, spices, tomatoes and cream.Its sauce is known for its rich texture.</p>
                                </div>
                            </div> 
                            <div className="menu-item">                                        
                                <div className="menu-text">
                                    <h3><span>Beef Fry</span></h3>
                                    <p>Staple South Indian dish. It's spiced with a special blend of spices, including meat masala.</p>
                                </div>
                            </div> 	
                            <div className="menu-item">                                        
                                <div className="menu-text">
                                    <h3><span>Pork Fry</span></h3>
                                    <p>Staple South Indian dish. It's spiced with a special blend of spices, including meat masala.</p>
                                </div>
                            </div>									
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default MenuPage