import React from 'react';
import carouselImage from '../../../assets/images/carousel-1.jpg'
import ubereats from '../../../assets/images/uber-eats.png'
import grubhub from '../../../assets/images/grubhub.png'
import menufy from '../../../assets/images/menufy.png'
import doordash from '../../../assets/images/doordash.png'

const Carousel = () => {
  return (
    <div>
        <div className="carousel">
            <div className="container-fluid">
                <div className="owl-carousel">
                    <div className="carousel-item">
                        <div className="carousel-img">
                            <img src={carouselImage} alt="Malabar lImage"/>
                        </div>
                        <div className="carousel-text">
                            
                            <h1 style={{paddingTop: "30px"}}>Welcome to <span>MALABAR</span> Foods!</h1>
                            <p>
                                We are a catering company committed to bringing you rich, delish traditional Indian food that’ll keep you craving for more! Our recipes, high-quality ingredients and preparation techniques come straight from our homeland, passed down through generations. We believe in making every moment unforgettable, and we go above and beyond to elevate your event with a taste of true Indian hospitality.​
                            </p>
                            <div class="wrapper one">
                                <div class="drop-main">
                                    <div class="d">ORDER</div>
                                    <div class="r">&nbsp;ONLINE</div>
                                    <div class="o">&nbsp;WITH</div>
                                    <div class="p">&nbsp;US</div>
                                    <div class="s">!</div>
                                </div>
                            </div>
                            <div className="carousel-btn">
                                {/*<a className="button-online" href="https://www.ordermalabarfoods4you.com/#/" target="_blank">Order Online</a> */}
                                  <div class="container">
                                    <div class="row">
                                        <div class="col-3">
                                            <a href="https://www.ubereats.com/store/malabar-foods/9KX9d3LFUcKxO0iJSCI0iA" target="_blank"><img class="img-online" src={ubereats} alt="Uber Eats"/></a>
                                        </div>
                                        <div class="col-3">
                                            <a href="https://www.grubhub.com/restaurant/malabar-foods-911-greenwood-rd--b-glenview/7483560" target="_blank"><img class="img-online" src={grubhub} alt="Grubhub"/></a>                                                             
                                        </div>
                                        <div class="col-3">
                                            <a href="https://www.ordermalabarfoods4you.com/#/" target="_blank"><img class="img-online" src={menufy} alt="Menufy"/></a>                                                               
                                        </div>
                                        <div class="col-3">
                                            <a href="https://www.doordash.com/store/malabar--foods-glenview-24563619" target="_blank"><img class="img-online" src={doordash} alt="Doordash"/></a>                                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Carousel