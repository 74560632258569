import React from 'react';
import logo from '../../assets/images/logo.PNG'
import("../../assets/css/style.css");
import("../../assets/js/main.js");

const Header = () => {
    return (       
        <div id="topheader" className="navbar navbar-expand-lg bg-light navbar-light">
            <div className="container-fluid">
			 <img src={logo} alt="Malabar Foods" style={{maxWidth: "100%",maxHeight: "80px"}}/>
                <a href="index.html" className="navbar-brand"> Malabar <span>Foods</span></a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                    <div className="navbar-nav ml-auto">
                        <a href="/" className="nav-item nav-link active">Home</a>
                        {/*<a href="/menu" className="nav-item nav-link">Menu</a>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;