import React from 'react';
import Carousel from './Carousel';
import AboutUs from './AboutUs';
import KeyFeatures from './KeyFeatures';
import Menu from './Menu';
import Contact from './Contact';

const HomePage = () => {
  return (
    <>
        <Carousel/>
        <AboutUs/>
        <KeyFeatures/>
        <Menu/>
        <Contact/>
    </>
  )
}

export default HomePage