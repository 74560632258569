import React from 'react';
import featureImage1 from '../../../assets/images/feature-1.jpg'
import featureImage2 from '../../../assets/images/feature-2.jpg'
import featureImage3 from '../../../assets/images/feature-3.jpg'
import featureImage4 from '../../../assets/images/feature-4.jpg'
import("../../../assets/lib/flaticon/font/flaticon.css");

const KeyFeatures = () => {
  return (
    <div>
        <div className="feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-header">
                            <p>Why Choose Us</p>
                            <h2>Our Key Features</h2>
                        </div>
                        <div className="feature-text">
                            <div className="feature-img">
                                <div className="row">
                                    <div className="col-6">
                                        <img src={featureImage3} alt="Feature Product"/>
                                    </div>
                                    <div className="col-6">
                                        <img src={featureImage2} alt="Feature Product"/>
                                    </div>
                                    <div className="col-6">
                                        <img src={featureImage1} alt="Feature Product"/>
                                    </div>
                                    <div className="col-6">
                                        <img src={featureImage4} alt="Feature Product"/>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <i className="flaticon-cooking"></i>
                                    <h3>WEDDING CATERING</h3>
                                    <p>
                                        Engagements | Banquets | Receptions
                                    </p>
									<p>We offer an unbeatable value for wedding catering with delivery and setup.</p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <i className="flaticon-cocktail"></i>
                                    <h3>CORPORATE CATERING</h3>
                                    <p>
                                        Conferences | Galas | Brunch Meetings
                                    </p>
									<p>Years in the industry allow us to bring the experience of a great Indian restaurant to your boardroom.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <i className="flaticon-fast-food"></i>
                                    <h3>PRIVATE EVENT CATERING</h3>
                                    <p>
                                        Birthdays | Anniversaries | Dinner Parties
                                    </p>
									<p>We are experts in creating unique and alluring experiences for your special occasion. Let us worry about the food while you enjoy hosting friends and family.</p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <i className="flaticon-meat"></i>
                                    <h3>POP-UP BOOTHS</h3>
                                    <p>
                                        Off-site events | Conventions | Meets
                                    </p>
									<p>Our team has catered for several outdoor venues through our portable popup booths. Bring a world of rich flavors and deep aromas to your attendees by crafting a custom menu for your outdoor events</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default KeyFeatures