import React from 'react';


const FooterPage = () => {
  return (
    <div>
        <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="footer-contact">
                                        <h2>Our Address</h2>
                                        <p><i className="fa fa-map-marker-alt"></i>911 Greenwood Rd # B, Glenview, IL 60025</p>
                                        <p><i className="fa fa-phone-alt"></i>847 998 5635</p>
                                        <p><i className="fa fa-envelope"></i>malabar4you@gmail.com</p>
                                        <div className="footer-social">                                        
                                            <a href="https://www.facebook.com/royalmalabarcatering/"><i className="fab fa-facebook-f"></i></a>                                        
                                            <a href="https://www.instagram.com/royalmalabar.catering/"><i className="fab fa-instagram"></i></a>                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-link">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <p>Copyright &copy; <a href="#">Malabar Grocery and Catering LLC</a>, All Right Reserved.</p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default FooterPage